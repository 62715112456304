import { createRouter, createMemoryHistory, createWebHistory } from 'vue-router';
import SuccessPage from './components/Success.vue';
import HelloWorld from './components/HelloWorld';
import HomePage from './components/HomePage';
const isServer = typeof window === 'undefined';
const history = isServer ? createMemoryHistory() : createWebHistory();
const routes = [
  {
    path: '/search',
    name: 'Home',
    component: HelloWorld,
    props:true
  },
  {
    path: '/verify',
    name: 'Result',
    component: SuccessPage,
    props:true
  },
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    props:true
  }

];

const router = createRouter({
  history,
  routes,
});

export default router;