<template>
   <div class="header">
        <h3>Hamer Candy Verification</h3>
    </div>
  <img alt="Vue logo" src="./assets/hamer.png" class="logo">
  <img alt="Vue logo" src="./assets/hamer_bg.jpg" class="logo">
  <img alt="Vue logo" src="./assets/active_bg.jpg" class="logo">
  <router-view />
  
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 110px;
}
.logo{
  width: 200px;
}
@media (max-width: 768px){
  .logo{
    width: 100px;
  }
  .title{
    font-size: 12px;
  }
}
.header{
  position: fixed;
  width: 100%;
  top: 0px;
  left:0px;
  height: 100px;
  text-align: center;
  /* vertical-align: middle; */
    background-color: #ab2328;
    color: aliceblue;
}
@media (max-width: 768px) {
  .header h3{
    font-size: 28px;
  }  
}
@media(min-width: 768px){
  .header h3{
    font-size: 32px;
  }
}
</style>
