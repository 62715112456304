<template>
  <!-- <body class="home page-template page-template-page-templates page-template-homepage page-template-page-templateshomepage-php page page-id-84 custom-background wp-custom-logo mesmerize mesmerize-front-page mesmerize-content-no-padding "> -->
    <div id="page" class="site">
      <div id='page-content' class="page-content">
        <div class="content">
          <div  data-label="QR Search" data-id="contact--1" data-export-id="contact-1" data-category="contact" class="contact-1 content-section content-section-spacing white-text" data-parallax-depth="20" id="qr-search" style="background-color: rgb(255, 255, 255); background-size: cover; background-position: center top; ">
            <div  class="gridContainer"> 
              <div  class="row text-center">
                <div  class="section-title-col" data-type="column"> 
                  <h2  class="">Authentication QR Code</h2> 
                  <p  class="lead">Please Key In Your Package QR Authentication Code</p>
                </div> 
              </div> 
              <div  class="row text-center"> 
                <div  class="col-xs-12 col-sm-8 col-sm-offset-2 contact-form-wrapper inline-info"> 
                  <div  class="card large-padding"> 
                    <div  class="" data-content-shortcode="mesmerize_contact_form shortcode=&quot;ivory-search id=&amp;amp;quot;381&amp;amp;quot; title=&amp;amp;quot;Default Search Form&amp;amp;quot;&quot;" data-editable="true">
                      <form  class="is-search-form is-form-style is-form-style-3 is-form-id-381 " @submit="directionResult('87324A2')" action="#" method="get" role="search" >
                        <label for="is-search-input-381">
                          <span class="is-screen-reader-text">Search for:</span>
                          <input  type="search" id="is-search-input-381" name="s" value="" class="is-search-input" placeholder="Scan or Key In QR Code" autocomplete=off />
                        </label>
                        <button type="submit" class="is-search-submit">
                          <span class="is-screen-reader-text">Search Button</span>
                          <span class="is-search-icon">
                            <svg focusable="false" aria-label="Search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px">
                              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                            </svg>
                          </span>
                        </button>
                        <input type="hidden" name="id" value="381" />
                      </form>
                    </div>
                  </div> 
                </div>
              </div> 
            </div>
          </div>        
        </div>
      </div>
	</div>
	<!-- </body> -->
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods:{
            directionResult(name){
              console.log(name);
                this.$router.push({path:'/result',query:{id:name}});
            }
        }
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.section-title-col-white-text .section-title-col *, .white-text, .white-text .section-title-col * {
    color: #AD3C61;
}
.h2, h2 {
    font-size: 2.5rem;
    line-height: 3rem;
}
.screen-reader-text[href="#page-content"]:focus {
        background-color: #f1f1f1;
        border-radius: 3px;
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
        clip: auto !important;
        clip-path: none;
        color: #21759b;

      }


      .section-title-col {
    color: #BFBFBF;
}
@media (min-width: 1228px){
  .section-title-col {
      max-width: 1024px;
  }
}
@media (min-width: 768px){
  .section-title-col {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      -webkit-flex-grow: 0;
      -moz-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -webkit-flex-shrink: 0;
      -moz-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      padding-right: 15px;
      padding-left: 15px;
      -webkit-flex-basis: 83.33333%;
      -moz-flex-basis: 83.33333%;
      -ms-flex-preferred-size: 83.33333%;
      flex-basis: 83.33333%;
      max-width: 83.33333%;
  }
}
.section-title-col {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
    text-align: center;
    margin: auto;
}
.row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -moz-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}
.card.large-padding {
    padding: 30px;
}
/* .card.large-padding {
    padding: 48px;
} */
.card {
    background-color: rgba(255,255,255,0.4);
    border-radius: 60px;
    /* box-shadow: inset 2px 3px 5px #999; */
}
.card {
    padding: 24px 40px;
    /* background-color: #fff; */
    box-shadow: 0 0 3px 0 rgba(189,189,189,.4), 0 2px 3px 0 rgba(189,189,189,.3), 0 2px 3px rgba(0,0,0,.08);
}

@media (min-width: 768px){
  .contact-form-wrapper {
      text-align: left;
  }
  .contact-form-wrapper {
      text-align: center;
  }
}
@media only screen and (min-width: 768px){
  .col-sm-offset-2 {
      -webkit-flex-grow: 0;
      -moz-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -webkit-flex-shrink: 0;
      -moz-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      margin-left: 16.66667%;
  }
}
@media only screen and (min-width: 768px){
  .col-sm-offset-2, .col-sm-offset-3 {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      padding-right: 15px;
      padding-left: 15px;
  }
}
@media only screen and (min-width: 768px){
  .col-sm-8 {
      -webkit-flex-grow: 0;
      -moz-flex-grow: 0;
      -ms-flex-positive: 0;
      flex-grow: 0;
      -webkit-flex-shrink: 0;
      -moz-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      -webkit-flex-basis: 66.66667%;
      -moz-flex-basis: 66.66667%;
      -ms-flex-preferred-size: 66.66667%;
      flex-basis: 66.66667%;
      max-width: 66.66667%;
  }
}
@media only screen and (min-width: 768px){
  .col-sm-7, .col-sm-8 {
      box-sizing: border-box;
      -webkit-box-flex: 0;
      padding-right: 15px;
      padding-left: 15px;
  }
}
.col-xs-12 {
    -webkit-flex-grow: 0;
    -moz-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -webkit-flex-shrink: 0;
    -moz-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    /* max-width: 100%; */
}
.col-xs-11, .col-xs-12 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    padding-right: 15px;
    padding-left: 15px;
}
.row>* {
    -webkit-flex-basis: 100%;
    -moz-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.contact-form-wrapper form {
    margin-top: 0;
    margin-bottom: 0;
}
.is-form-style {
    line-height: 1;
    position: relative;
    padding: 0!important;
}
.form-item, form {
    margin-bottom: 2rem;
}
.is-form-style.is-form-style-3 label {
    width: calc(100% - 36px)!important;
}
.contact-form-wrapper label {
    font-family: Muli,sans-serif;
    font-weight: 700;
}
.contact-form-wrapper label, .upper {
    text-transform: uppercase;
}
.contact-form-wrapper label, .text-left {
    text-align: left;
}
.contact-form-wrapper label, .h6, h6 {
    font-size: .875rem;
    line-height: 1.375rem;
    font-weight: 800;
    letter-spacing: .1875rem;
}
.is-form-style label {
    display: inline-block!important;
    padding: 0;
    vertical-align: middle;
    margin: 0;
    width: 100%;
    line-height: 1;
}
label {
    color: #313439;
    margin-bottom: 4px;
    font-size: 15px;
}
.is-form-style button.is-search-submit {
    background: 0 0;
    border: 0;
    box-shadow: none!important;
    opacity: 1;
    padding: 0!important;
    margin: 0;
    line-height: 0;
    outline: 0;
    vertical-align: middle;
    width: 36px;
    height: 36px;
}
.is-screen-reader-text {
    border: 0;
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute!important;
    width: 1px;
    word-wrap: normal!important;
    word-break: normal;
}
.is-form-style.is-form-style-3 input.is-search-input {
    border-radius: 60px 0px 0px 60px;
}
.is-form-style.is-form-style-3 input.is-search-input {
    border-right: 0!important;
}
/* .is-form-style-1.is-form-id-381 .is-search-input:focus, .is-form-style-1.is-form-id-381 .is-search-input:hover, .is-form-style-1.is-form-id-381 .is-search-input, .is-form-style-2.is-form-id-381 .is-search-input:focus, .is-form-style-2.is-form-id-381 .is-search-input:hover, .is-form-style-2.is-form-id-381 .is-search-input, .is-form-style-3.is-form-id-381 .is-search-input:focus, .is-form-style-3.is-form-id-381 .is-search-input:hover, .is-form-style-3.is-form-id-381 .is-search-input, .is-form-id-381 .is-search-input:focus, .is-form-id-381 .is-search-input:hover, .is-form-id-381 .is-search-input {
    color: #dddddd !important;
} */
.is-form-style input.is-search-input {
    background: #fff;
    background-image: none!important;
    color: #333;
    padding: 0 12px;
    margin: 0;
    outline: 0!important;
    font-size: 14px!important;
    height: 36px;
    min-height: 0;
    line-height: 1;
    border-radius: 0;
    border: solid 1px #ccc!important;
    font-family: arial;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-border-radius: 0;
    border-radius: 0;
}
.is-screen-reader-text {
    border: 0;
    clip: rect(1px,1px,1px,1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute!important;
    width: 1px;
    word-wrap: normal!important;
    word-break: normal;
}
input.search, input[type=search] {
    border-radius: 60px;
}
input.search, input[type=search] {
    background-repeat: no-repeat;
    background-position: 8px 53%;
    padding-left: 32px;
}
input, select, textarea {
    display: block;
    width: 100%;
    font-size: 1rem;
    height: 2.5rem;
    outline: 0;
    background-color: #fff;
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    box-shadow: none;
    padding: 0 12px;
}
.is-form-style input.is-search-submit, .is-search-icon {
    display: inline-block!important;
    color: #666;
    background: #ededed;
    box-shadow: none!important;
    outline: 0;
    margin: 0;
    font-size: 14px!important;
    border: 1px solid #ccc;
    border-radius: 0;
    line-height: 1;
    height: 36px;
    text-transform: capitalize;
    vertical-align: middle;
    -webkit-transition: background-color .1s ease-in-out;
    -moz-transition: background-color .1s ease-in-out;
    -o-transition: background-color .1s ease-in-out;
    transition: background-color .1s ease-in-out;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.is-form-style input.is-search-submit, .is-search-icon {
    border-radius: 0px 60px 60px 0px;
}
.is-search-icon {
    width: 36px;
    padding-top: 6px!important;
}
::placeholder{
  opacity: 0.3 ;
}
</style>
