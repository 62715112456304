import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faHome)

const app=createApp(App);
app.use(router);
app.component("font-awesome-icon", FontAwesomeIcon);
app.config.globalProperties.serverUrl = 'http://auto.wpm-i.com:8080/api/v1/';
app.mount('#app')
