<template>
   <div id="page" v-if="loading" class="site">
      <div id='page-content' class="page-content">
        <div class="content">
          <div  data-label="QR Search" data-id="contact--1" data-export-id="contact-1" data-category="contact" class="contact-1 content-section content-section-spacing white-text" data-parallax-depth="20" id="qr-search" style="background-color: rgb(255, 255, 255); background-size: cover; background-position: center top; ">
            <div  class="gridContainer"> 
              <div  class="row text-center">
                <div  class="section-title-col" data-type="column"> 
                  <h2  class="title">Serial No:</h2> 
                  <p  class="lead">{{ id }}</p>
                </div> 
              </div> 
              <img alt="Vue logo" src="../assets/tr_pack.png" class="icon_logo" v-if="candy_type==0">
              <img alt="Vue logo" src="../assets/active.jpg" class="icon_logo" v-if="candy_type==1">
              <div  class="row text-center"> 
                <div  class="col-xs-12 col-sm-8 col-sm-offset-2 contact-form-wrapper inline-info"> 
                  <div  class="card large-padding"> 
                    <div  class="" data-content-shortcode="mesmerize_contact_form shortcode=&quot;ivory-search id=&amp;amp;quot;381&amp;amp;quot; title=&amp;amp;quot;Default Search Form&amp;amp;quot;&quot;" data-editable="true">
                      <h2 v-if="candy_type==0" class="title">Packing: <b class="intro">HAM621.CO</b></h2>
                      <h2  v-if="candy_type==1" class="title">Packing: <b class="intro">ACTIVE2U.COM</b></h2>
                    </div>
                  </div> 
                </div>
              </div> 
              <div  class="row text-center"> 
                <div  class="col-xs-4 col-sm-8 col-sm-offset-2 contact-form-wrapper inline-info"> 
                  <div  class="card large-padding"> 
                    <div  class="shipped" style="border-radius: 10px 10px 0 0;">
                      <h2  class="title">Shipped out to: <b class="intro">MALAYSIA</b></h2>
                    </div>
                  </div> 
                </div>
              </div>
              <div  class="row text-center" style="margin-top: -3px;"> 
                <div  class="col-xs-12 col-sm-8 col-sm-offset-2 contact-form-wrapper inline-info"> 
                  <div  class="card large-padding"> 
                    <div  class="shipped" style="border-radius:0 0 10px 10px;">
                      <h2  class="title">ON: <b class="intro">2023-03-31</b></h2>
                    </div>
                  </div> 
                </div>
              </div>
              <div  class="row text-center"> 
                <div  class="col-xs-12 col-sm-8 col-sm-offset-2 contact-form-wrapper inline-info"> 
                  <div  class="card large-padding"> 
                    <div  class="" data-content-shortcode="mesmerize_contact_form shortcode=&quot;ivory-search id=&amp;amp;quot;381&amp;amp;quot; title=&amp;amp;quot;Default Search Form&amp;amp;quot;&quot;" data-editable="true">
                      <b class="lead">STATUS</b>
                    </div>
                  </div> 
                </div>
              </div>
              <div  class="row text-center"> 
                <div  class="col-xs-12 col-sm-8 col-sm-offset-2 contact-form-wrapper inline-info"> 
                  <div  class="card large-padding"> 
                    <div  class="" data-content-shortcode="mesmerize_contact_form shortcode=&quot;ivory-search id=&amp;amp;quot;381&amp;amp;quot; title=&amp;amp;quot;Default Search Form&amp;amp;quot;&quot;" data-editable="true">
                      <img src="../assets/original.png" class="status">
                    </div>
                  </div> 
                </div>
              </div>
              <div  class="row text-center"> 
                <div  class="col-xs-12 col-sm-8 col-sm-offset-2 contact-form-wrapper inline-info"> 
                  <div  class="card large-padding"> 
                    <div  class="" data-content-shortcode="mesmerize_contact_form shortcode=&quot;ivory-search id=&amp;amp;quot;381&amp;amp;quot; title=&amp;amp;quot;Default Search Form&amp;amp;quot;&quot;" data-editable="true">
                      <img src="../assets/success.png" class="remark">
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>        
        </div>
      </div>
	</div>
  <div id="page_error" v-if="!loading" class="site">
      <div id='page-content' class="page-content">
        <div class="content">
          <div  data-label="QR Search" data-id="contact--1" data-export-id="contact-1" data-category="contact" class="contact-1 content-section content-section-spacing white-text" data-parallax-depth="20" id="qr-search" style="background-color: rgb(255, 255, 255); background-size: cover; background-position: center top; ">
            <div  class="gridContainer"> 
              <div  class="row text-center">
                <div  class="section-title-col" data-type="column"> 
                  <h2  class="lead">We have no record<br> of your product details<br> in our database.</h2> 
                </div> 
              </div> 
              <img alt="Vue logo" src="../assets/fake.png" class="remark">
              <!-- <img alt="Vue logo" src="../assets/fake.png" class="remark"> -->
              <div  class="row text-center"> 
                <div  class="col-xs-12 col-sm-8 col-sm-offset-2 contact-form-wrapper inline-info"> 
                  <div  class="card large-padding"> 
                    <div  class="" >
                      <b class="lead">STATUS</b>
                    </div>
                  </div> 
                </div>
              </div>
              <div  class="row text-center"> 
                <div  class="col-xs-12 col-sm-8 col-sm-offset-2 contact-form-wrapper inline-info"> 
                  <div  class="card large-padding"> 
                    <div  class="">
                      <img src="../assets/unknown.png" class="status">
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>        
        </div>
      </div>
	</div>
  <a href="https://ham621.co" v-if="candy_type==0" class="row">
    <img alt="Vue logo" src="../assets/back_home.png" class="logo">
  </a>
  <a href="https://active2u.com" v-if="candy_type==1" class="row">
    <img alt="Vue logo" src="../assets/back_home.png" class="logo">
  </a>
</template>
<script>
    import axios from 'axios';
    export default{
        name:"SuccessPage",
        data(){
            return{
                id: '',
                token:'',
                loading: true,
                candy_type:0
            }
        },
        mounted(){
          this.id=this.$route.query.id;
          this.token=this.$route.query.token;
          console.log(this.serverUrl);
          axios.get(this.serverUrl+"QrCodesToken/"+this.id+"/"+this.token)
                .then((response) => {
                  console.log(response);
                  const data = response.data;
                  console.log(data); 
                  const idd=data.id;
                  console.log(idd);
                  this.loading= idd>0;
                  this.candy_type=data.candy_type;
                  console.log(this.candy_type);
                    // this.regionList=data.content;
                    // this.totalPage=data.totalPages;
                    // this.isPage=data.totalPages>1;
                })
        }
    }
</script>
<style>
.header{
    background-color: #ab2328;
}
.lead{
  font-size: 64px;
  font-weight: 900;
  margin: 10px;
}
.intro{
  font-size: 32px;
  font-weight: 900;
  margin: 10px;
  color: black;
}
.title{
  font-size: 30px;
  margin: 0;
  font-family: arial;
  color: darkgrey;
}

.shipped{
  border: #ab2328 3px solid;
    /* width: 30%; */
    margin: 0 30%;
    padding: 15px;
}
.icon_logo{
  width: 200px;
}
.row{
  margin-top: 25px;
}
.status{width: 30%;}
.remark{width: 50%;}
@media (max-width: 768px){
   .title{font-size: 20px;}
  .lead{ font-size: 32px;}
  .intro{font-size: 20px;}
  .row{margin-top: 5px;}
  .shipped{margin: 0 2%;}
  .status{width: 70%;}
  .remark{width: 90%;}
}
</style>